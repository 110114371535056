// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
.form-border-first {
    border-radius: 5px 0 0 5px;
}
.form-border-second {
    border-radius: 0;
}
.form-border-third {
    border-radius: 0 5px 5px 0;
}
.form-products-links {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}

.text-violet {
    color: #801fca;
}
